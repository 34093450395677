import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { AppRoutesPaths } from '../app-routes';

@Injectable({ providedIn: 'root' })
export class WwbModeService {
    MODULES_WITH_WWB_MODE_ENABLED: AppRoutesPaths[] = [
        AppRoutesPaths.SITE,
        AppRoutesPaths.CAMPAIGN
    ];
    private _isWwbMode$ = new BehaviorSubject(false);

    get isWwbMode$() {
        return this._isWwbMode$.asObservable();
    }

    get isWwbMode() {
        return this._isWwbMode$.value;
    }

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router
    ) {
        this.initWwbMode();
    }

    initWwbMode() {
        this.activatedRoute.queryParams.subscribe((params) => {
            const currentRoute = this.router.url
                .split('/')
                .filter((v) => !!v)[0] as AppRoutesPaths;
            if (this.MODULES_WITH_WWB_MODE_ENABLED.includes(currentRoute)) {
                this._isWwbMode$.next(!!params.wwb);
            }
        });
    }
}
